.Spinner {
    width: 100px;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.Spinner img {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin:-40px 0 0 -40px;
    -webkit-animation: spin 3s linear 0.5s infinite;
    -moz-animation: spin 3s linear 0.5s infinite;
    animation: spin 3s linear 0.5s infinite;
}

.Spinner.Spinner-immediate img {
    animation-delay: 0s;
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
}

@-moz-keyframes spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
